exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mma-india-js": () => import("./../../../src/pages/about-mma-india.js" /* webpackChunkName: "component---src-pages-about-mma-india-js" */),
  "component---src-pages-digital-partner-js": () => import("./../../../src/pages/digital-partner.js" /* webpackChunkName: "component---src-pages-digital-partner-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mma-js": () => import("./../../../src/pages/mma.js" /* webpackChunkName: "component---src-pages-mma-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

